import React from "react";
import BpOnBoarding from "../containers/BP/BPOnBoarding/index";
import { enableBP } from "gatsby-env-variables";
import UpdateButton from "../common/components/updateButton/UpdateButton";
const BP = () => {
  return enableBP ? (
    <UpdateButton message="This application has been updated. It will be reloaded soon.">
      <BpOnBoarding />
    </UpdateButton>
  ) : (
    ""
  );
};

export default BP;
